import React, { useEffect, useState } from 'react'
import { Button } from 'antd';
import axios from 'axios';
import TotalCollectionsTable from './TotalCollectionsTable';
import DashBoardCounter from './DashBoardCounter';
import TotalClient from './TotalClient';
import DefaulterList from './DefaulterList';
import DepositLateFees from './DepositLateFees';
import { useDispatch, useSelector } from 'react-redux';
import { setCashInHandAmountAmount, setCounterBalance, setCounterCollection, setCurrentCounterNo, setTotalCollection } from '../../redux/actions/ProductActions';
import { BackendRootStrings, RouteStrings } from '../Routes';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import CurrentMonthCollection from './CurrentMonthCollection';
import Counter6Data from './Counter6Data';
import Counter7Data from './Counter7Data';

interface PaymentObject {
  Payment_Months: {
    Late_Penalty: number;
    Total_Interest: number;
  }[];
}

const DashBoardItem1 = () => {

  //Redux part
  const products: { allProducts: { noOfMembers: number, totalCollectionAmount: number, depositLateFees: number } } = useSelector((state: { allProducts: { noOfMembers: number, totalCollectionAmount: number, depositLateFees: number } }) => state);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [tabNo, setTabNo] = useState(0);

  let [totalCollectionAmountInDashBoard, setTotalCollectionAmountInDashBoard] = useState(0);

  const [userName, setUserName] = useState("Default");
  const [userType, setUserType] = useState("");

  let [totalLoanAmount, setTotalLoanAmount] = useState(0);

  let [uniqueLoanIdAndStatusArray, setUniqueLoanIdAndStatusArray] = useState<{ Status: string, UniqueLoanID: string }[]>([{ Status: "Pending", UniqueLoanID: "" }]);


  let [loanLateFineAndInterest, setLoanLateFineAndInterest] = useState({ loanLateFine: 0, loanInterest: 0 });

  let [depositLateFees, setDepositLateFees] = useState(0);

  const [cashInHand, setcashInHand] = useState(0);

  let cookies = new Cookies();


  const handleTotalCollection = async () => {
    setTabNo(0);
    const { data } = await axios.get(`${BackendRootStrings.apiHome}/master`);
    dispatch(setTotalCollection(data.message));
  }

  const counterCollection = async (no: number) => {
    setTabNo(1);
    dispatch(setCurrentCounterNo(no));
    const { data } = await axios.get(`${BackendRootStrings.apiHome}/counterCollection/${no}`);
    dispatch(setCounterCollection(data.message));
    // if (no === 6) {
    //   axios.get(`${BackendRootStrings.apiHome}/getTotalCounterCollectionById/${no}`)
    //   .then(({data})=>{
    //     const {message}=data;
    //     dispatch(setCounterBalance(message));
    //   })
    // }else{
    const CollectedAmount = await axios.get(`${BackendRootStrings.apiHome}/getCollectedAmountForEachCounter`);
    let balance = 0;
    let current = (Object.values(CollectedAmount.data)[no - 1]) as { AmountCollected: number, totalCounterReceived: number, totalCounterTransferred: number, };
    balance = current.AmountCollected + current.totalCounterReceived - current.totalCounterTransferred;
    dispatch(setCounterBalance(balance));
    // }




  }

  const handleCounterSixInfo = async () => {
    setTabNo(15);
    // axios.get(`${BackendRootStrings.apiHome}/getTotalCounterCollectionById/${6}`)
    //   .then(({ data }) => {
    //     const { message } = data;
    //     dispatch(setCounterBalance(message));
    //   })
    const CollectedAmount = await axios.get(`${BackendRootStrings.apiHome}/getCollectedAmountForEachCounter`);
    let balance = 0;
    let current = (Object.values(CollectedAmount.data)[5]) as { AmountCollected: number, totalCounterReceived: number, totalCounterTransferred: number, };
    balance = current.AmountCollected + current.totalCounterReceived - current.totalCounterTransferred;
    dispatch(setCounterBalance(balance));
  }

  const handleDefaulterList = async () => {
    setTabNo(5);
  }
  const handleLateFeesList = async () => {
    setTabNo(3);
  }

  const renderTabContent = () => {
    if (tabNo === 0) {
      return <TotalCollectionsTable />;
    } else if (tabNo === 1) {
      return <DashBoardCounter />;
    }
    else if (tabNo === 3) {
      return <DepositLateFees />;
    }
    else if (tabNo === 5) {
      return <DefaulterList />;
    }
    else if (tabNo === 8) {
      return <TotalClient />;
    }
    else if (tabNo === 14) {
      return <CurrentMonthCollection />;
    }
    else if (tabNo === 15) {
      return <Counter6Data />;
    }
    else if (tabNo === 16) {
      return <Counter7Data />;
    }
  };

  useEffect(() => {
    let tempUserName = cookies.get("UserName");
    let tempUserType = cookies.get("UserType");
    setUserName(tempUserName);
    setUserType(tempUserType);
    handleTotalCollection();
  }, [])

  const handleCashInHandData = async () => {

    let allDepositData = [{ Amount: 0, Late_Fees: 0 }];
    let allLoanRepaymentData = [{ UniqueLoanID: "", Payment_Months: [{ Total_Interest: "", Principal_Amount: "", Late_Penalty: "" }] }];
    await axios.get(`${BackendRootStrings.apiHome}/getAllDepositDataFromDeposit`)
      .then(({ data }) => {
        const { message } = data;
        allDepositData = message;
      })
    await axios.get(`${BackendRootStrings.apiHome}/getAllLoanRepayments`)
      .then(({ data }) => {
        const { message } = data;
        allLoanRepaymentData = message;
      })

    await axios.get(`${BackendRootStrings.apiHome}/getAllLoanDataWithUniqueLoanIdAndStatus`)
      .then(({ data }) => {
        const { message } = data;
        setUniqueLoanIdAndStatusArray(message);
        uniqueLoanIdAndStatusArray = message;
      })
    let totalAmount = 0;
    setTotalCollectionAmountInDashBoard(0);
    totalCollectionAmountInDashBoard = 0;
    allDepositData.map((obj, ind) => {
      totalAmount += ((obj.Amount.toString() !== "") ? parseInt(obj.Amount?.toString()) : 0) +
        ((isNaN(parseFloat(obj.Late_Fees?.toString())) || (obj.Late_Fees.toString() === "")) ? 0 : parseFloat(obj.Late_Fees?.toString()));
    })
    setTotalLoanAmount(0);
    totalLoanAmount = 0;
    setTotalCollectionAmountInDashBoard(totalCollectionAmountInDashBoard + totalAmount);
    totalCollectionAmountInDashBoard += totalAmount;
    for (let i = 0; i < allLoanRepaymentData.length; i++) {
      let totalCollectionTemp = 0;
      for (let j = 1; j < allLoanRepaymentData[i]?.Payment_Months.length; j++) {
        totalAmount += parseInt(allLoanRepaymentData[i]?.Payment_Months[j].Principal_Amount) +
          parseInt(allLoanRepaymentData[i]?.Payment_Months[j].Total_Interest) +
          parseInt(allLoanRepaymentData[i]?.Payment_Months[j].Late_Penalty);
      }
      for (let j = 1; j < allLoanRepaymentData[i]?.Payment_Months.length; j++) {
        totalCollectionTemp += parseInt(allLoanRepaymentData[i]?.Payment_Months[j].Principal_Amount) +
          parseInt(allLoanRepaymentData[i]?.Payment_Months[j].Total_Interest) +
          parseInt(allLoanRepaymentData[i]?.Payment_Months[j].Late_Penalty);
      }
      let currentStatus = uniqueLoanIdAndStatusArray.filter((value, index) => {
        return value.UniqueLoanID === allLoanRepaymentData[i].UniqueLoanID
      });

      setTotalCollectionAmountInDashBoard(totalCollectionAmountInDashBoard + totalCollectionTemp);
      totalCollectionAmountInDashBoard += totalCollectionTemp;
      if (currentStatus.length > 0 && currentStatus[0].Status !== "Pending") {
        setTotalLoanAmount(totalLoanAmount + parseInt(allLoanRepaymentData[i]?.Payment_Months[0]?.Principal_Amount.toString()) ?? 0);
        totalLoanAmount += parseInt(allLoanRepaymentData[i].Payment_Months[0]?.Principal_Amount.toString()) ?? 0;
        totalAmount -= parseInt(allLoanRepaymentData[i].Payment_Months[0]?.Principal_Amount.toString()) ?? 0;
      }

    }

    setcashInHand(totalAmount);
    dispatch(setCashInHandAmountAmount(totalAmount))

  }

  useEffect(() => {


    axios.get(`${BackendRootStrings.apiHome}/getLateFessDetailsOfUsers`)
      .then(({ data }) => {
        const { message } = data;

        setDepositLateFees(0);
        depositLateFees = 0;
        message.map((obj: {
          Name: string;
          TNo: number;
          Fees: number[];
        }, ind: number) => {
          for (let i = 0; i < obj?.Fees?.length ?? 0; i++) {
            setDepositLateFees(depositLateFees + (isNaN(parseFloat(obj?.Fees[i]?.toString())) ? 0 : parseFloat(obj?.Fees[i]?.toString())));
            depositLateFees += (isNaN(parseFloat(obj?.Fees[i]?.toString())) ? 0 : parseFloat(obj?.Fees[i]?.toString()));
          }
        })
      })

  }, [])


  useEffect(() => {
    axios.get(`${BackendRootStrings.apiHome}/getAllLoanRepayment`)
      .then(({ data }) => {
        const { message } = data;
        loanLateFineAndInterest.loanInterest = 0;
        loanLateFineAndInterest.loanLateFine = 0;
        setLoanLateFineAndInterest({ loanInterest: 0, loanLateFine: 0 })
        message.map((obj: PaymentObject) => {
          for (let i = 1; i < obj.Payment_Months.length; i++) {
            setLoanLateFineAndInterest(prevState => {
              return {
                ...prevState,
                loanLateFine: prevState.loanLateFine + parseFloat(obj.Payment_Months[i].Late_Penalty?.toString()),
                loanInterest: prevState.loanInterest + parseFloat(obj.Payment_Months[i].Total_Interest.toString()),
              }
            })
          }
        })
      })

  }, [])

 const handleCounterSevenInfo=()=>{
  setTabNo(16);
 }




  useEffect(() => {
    handleCashInHandData();

  }, [])


  const handleLoanDirect = () => {
    navigate(RouteStrings.Loans, {
      state: {
        path: 'AllLoans',
      }
    });
  }




  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "100%" }}>
      <div style={{ width: "105%", height: "40%", display: "flex", justifyContent: "center", border: "1px solid" }}>
        <div style={{ width: "80%", height: "100%", display: "flex", flexWrap: "wrap", alignItems: "center", }}>
          <Button type="primary" size={'large'} style={{ height: 40, width: 236, marginRight: 5 }} onClick={() => setTabNo(8)}>
            TOTAL CLIENT ({products.allProducts.noOfMembers})
          </Button>
          <Button type="primary" size={'large'} style={{ height: 40, width: 236, marginRight: 5, textAlign: "left" }} onClick={handleTotalCollection}>
            {/* TOTAL COLLECTION ₹ {parseInt(products.allProducts.totalCollectionAmount.toString()) + (isNaN(depositLateFees) ? 0 : depositLateFees)} */}
            <span style={{ fontSize: 12, fontWeight: "bold" }}>TOTAL COLLECTION <span style={{ fontSize: 15 }}>₹</span></span>{totalCollectionAmountInDashBoard}
          </Button>
          <Button type="primary" size={'large'} style={{ height: 40, width: 236, marginRight: 5 }}>
            CASH IN HAND ₹ {isNaN(cashInHand) ? 0 : cashInHand}
          </Button>
          <Button type="primary" size={'large'} style={{ height: 40, width: 236, marginRight: 5 }} onClick={handleLoanDirect}>
            TOTAL LOAN ₹ {isNaN(totalLoanAmount) ? 0 : totalLoanAmount}
          </Button>

          <Button type="primary" size={'large'} style={{ height: 40, width: 236, marginRight: 5 }} onClick={() => { }}>
            LOAN LATE FINE ₹ {loanLateFineAndInterest.loanLateFine}
          </Button>
          <Button type="primary" size={'large'} style={{ height: 40, width: 236, marginRight: 5 }} onClick={() => { }}>
            LOAN INTEREST ₹ {loanLateFineAndInterest.loanInterest}
          </Button>
          <Button type="primary" size={'large'} style={{ height: 40, width: 236, marginRight: 5 }} onClick={handleLateFeesList}>
            REGISTRATION FEES  ₹ {isNaN(depositLateFees) ? 0 : depositLateFees}
          </Button>
          <Button type="primary" size={'large'} style={{ height: 40, width: 236, marginRight: 5 }} onClick={() => setTabNo(14)}>
            THIS MONTH COLLECTION
          </Button>
          <Button type="primary" size={'large'} style={{ height: 40, width: 236, marginRight: 5 }} onClick={handleDefaulterList}>
            DEFAULTER LIST
          </Button>
          {
            (userType === "Counter-1" || userType === "Admin") && <Button type="primary" size={'large'} style={{ height: 40, width: 236, marginRight: 5, display: (userType === "Counter-1" || userType === "Admin") ? "block" : "none" }} onClick={() => counterCollection(1)}>
              COUNTER 1
            </Button>
          }
          {
            (userType === "Counter-2" || userType === "Admin") && <Button type="primary" size={'large'} style={{ height: 40, width: 236, marginRight: 5, display: (userType === "Counter-2" || userType === "Admin") ? "block" : "none" }} onClick={() => counterCollection(2)}>
              COUNTER 2
            </Button>
          }
          {
            (userType === "Counter-3" || userType === "Admin") && <Button type="primary" size={'large'} style={{ height: 40, width: 236, marginRight: 5, display: (userType === "Counter-3" || userType === "Admin") ? "block" : "none" }} onClick={() => counterCollection(3)}>
              COUNTER 3
            </Button>
          }
          {
            (userType === "Counter-4" || userType === "Admin") && <Button type="primary" size={'large'} style={{ height: 40, width: 236, marginRight: 5, display: (userType === "Counter-4" || userType === "Admin") ? "block" : "none" }} onClick={() => counterCollection(4)}>
              COUNTER 4
            </Button>
          }
          {
            (userType === "Counter-5" || userType === "Admin") && <Button type="primary" size={'large'} style={{ height: 40, width: 236, marginRight: 5, display: (userType === "Counter-5" || userType === "Admin") ? "block" : "none" }} onClick={() => counterCollection(5)}>
            COUNTER 5
          </Button>
          }
          {
            (userType === "Counter-6" || userType === "Admin") && <Button type="primary" size={'large'} style={{ height: 40, width: 236, marginRight: 5, display: (userType === "Counter-6" || userType === "Admin") ? "block" : "none" }} onClick={handleCounterSixInfo}>
            COUNTER 6
          </Button>
          }
          {
            (userType === "Counter-7" || userType === "Admin") && <Button type="primary" size={'large'} style={{ height: 40, width: 236, marginRight: 5, display: (userType === "Counter-7" || userType === "Admin") ? "block" : "none" }} onClick={handleCounterSevenInfo}>
            COUNTER 7
          </Button>
          }
          
          

          {/* <Button type="primary" size={'large'} style={{ height: 40, width: 236, marginRight: 5 }}>
            TODAY COLLECTION
          </Button> */}

        </div>
      </div>

      <div style={{ height: "70%", }}>
        {renderTabContent()}


      </div>
    </div>

  )
}

export default DashBoardItem1